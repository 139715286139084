import styled from 'styled-components';
import bkg from '../../../content/blog/assets/bkg2.png';

export const Wrapper = styled.div`
  min-height: 100vh;
  z-index: -1;
  /* background-image: url('https://i.imgur.com/uH6qYuy.png'); */
  background-image: url(${bkg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  scroll-behavior: smooth;
`

export const Main = styled.main`
  margin-top: 2.8rem;
  width: 100%;

  @media(max-width: 768px) {
    margin-top: 20px;
  }
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 67%;
  z-index: 2;
  height: 100%;

  ${(props) => `
    max-width: ${props.width};
  `}

  @media(max-width: 1200px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`