import React from "react"
import { Wrapper, MainContainer, Main } from './styles' 
import Footer from '../footer'
 
export default function Layout({ children }) {
  return (
    <Wrapper>
      <MainContainer>
        {/* <Header title={title} location={location} /> */}
        <Main>{children}</Main>
      </MainContainer>
      <Footer />
    </Wrapper>
  )
}

