import React from 'react'
import logo from '../../../content/assets/rockywhite.png'
import fbLogo from '../../../content/assets/iconmonstr-facebook.png'
import igLogo from '../../../content/assets/iconmonstr-instagram.png'
import lkdLogo from '../../../content/assets/iconmonstr-linkedin.png'

import { 
  Logos, 
  BrandLogo, 
  RockyLogo, 
  FooterContainer,
  Address,
  LogoLink
} from './styles'

export default function Footer() {
  return (
    <FooterContainer>
      <Logos>
        <RockyLogo src={logo} />
        <LogoLink href="https://www.facebook.com/rockypublicidade/">
          <BrandLogo src={fbLogo} />
        </LogoLink>
        <LogoLink href="https://www.instagram.com/agencia.rocky/">
          <BrandLogo src={igLogo} />
        </LogoLink>
        <LogoLink href="https://www.linkedin.com/company/rocky-ag/">
          <BrandLogo src={lkdLogo} />
        </LogoLink>
      </Logos>
      <Address>R. Romeu do Nascimento, 247 - Jardim Portal da Colina, Sorocaba - SP</Address>
    </FooterContainer>        
  )
}