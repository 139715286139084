import styled from 'styled-components'

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #393939; 
  width: 100%;
  justify-content: center;
`

export const Logos = styled.div`
  padding: 10px 30px 0 30px;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const LogoLink = styled.a`
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: none;
  margin-right: -10px;
`

export const BrandLogo = styled.img`
  margin: 0;
  height: 45px;
  width: 45px;

  @media(max-width: 450) {
    height: 50px;
    width: 50px;
  }
`

export const RockyLogo = styled.img`
  margin: 0;
  height: 40px;
  width: 150px;

  @media(max-width: 450px) {
    height: 40px;
    width: 120px;
  }
`

export const Address = styled.p`
  padding: 0 30px 0px 30px;
  color: #fff;
  font-size: 16px;
`